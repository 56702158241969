import { lazy } from 'react';
import { ApplicationShell, setupGlobalErrorListener } from '@commercetools-frontend/application-shell';
import type { ApplicationWindow } from '@commercetools-frontend/constants';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { SettingsProvider, SocketProvider } from '../../context';
import { businessToolsStore } from '../../store/businessToolsStore';
import { GlobalNotificationProvider } from '../../context/GlobalNotification';

declare let window: ApplicationWindow;

// Here we split up the main (app) bundle with the actual application business logic.
// Splitting by route is usually recommended and you can potentially have a splitting
// point for each route. More info at https://reactjs.org/docs/code-splitting.html
const AsyncApplicationRoutes = lazy(() => import('../../routes' /* webpackChunkName: "routes" */));

// Ensure to setup the global error listener before any React component renders
// in order to catch possible errors on rendering/mounting.
setupGlobalErrorListener();

export function EntryPoint() {
  return (
    <IntlProvider locale="en-GB">
      <ApplicationShell environment={window.app} applicationMessages={{ en: [] }}>
        <SettingsProvider>
          <GlobalNotificationProvider>
            <Provider store={businessToolsStore}>
              <SocketProvider>
                <AsyncApplicationRoutes />
              </SocketProvider>
            </Provider>
          </GlobalNotificationProvider>
        </SettingsProvider>
      </ApplicationShell>
    </IntlProvider>
  );
}
