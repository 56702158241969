/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Store } from '@ct/types';

export interface AppConfigState {
  currentSite: Store;
  staged: boolean;
}

const initialState: AppConfigState = {
  currentSite: Store.BEAVERBROOKS,
  staged: false,
};

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setCurrentSite: (state, action: PayloadAction<Store>) => {
      state.currentSite = action.payload;
    },

    setStaged: (state, action: PayloadAction<boolean>) => {
      state.staged = action.payload;
    },
  },
});

export const { setCurrentSite, setStaged } = appConfigSlice.actions;

export default appConfigSlice.reducer;
