import type { ImportType } from '@ct/import-export';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect } from 'react';
import type { Store } from '@ct/types';
import { useBusinessToolsDispatch } from '../store/businessToolsStore';
import { useGetParamsByPrefix } from '../hooks/ssm';
import { useSettings } from './SettingsProvider';
import { setLastImports } from '../slices/importExportSlice';

export type LastImport = Partial<Record<ImportType, string>>;

const LastImportContext = createContext<LastImport>({});
export const useLastImport = () => useContext(LastImportContext);

export function LastImportProvider({ children, site }: { children: ReactNode; site: Store }) {
  const { importReportsBucketName } = useSettings();
  const dispatch = useBusinessToolsDispatch();

  const params = useGetParamsByPrefix(`/${site}/${importReportsBucketName}/last-execution-date`);

  useEffect(() => {
    dispatch(setLastImports(params));
  }, [dispatch, params]);

  return <LastImportContext.Provider value={params as LastImport}>{children}</LastImportContext.Provider>;
}
