import type { ListObjectsV2CommandInput, S3Client, UploadPartCommandInput, _Object } from '@aws-sdk/client-s3';
import {
  PutObjectCommand,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  ListObjectsV2Command,
  GetObjectCommand,
} from '@aws-sdk/client-s3';
import pLimit from 'p-limit';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { format } from 'date-fns';
import type { ListS3ObjectsInput, S3Object, UploadS3ObjectInput } from '../types';
import { decodeFileInformation, hasMoreThan100Mb, parseFileNameDate } from './file';

export async function singleUpload(
  s3Client: S3Client,
  { file, name, bucketName, connectionId, userInfo }: UploadS3ObjectInput,
) {
  const command = new PutObjectCommand({
    Bucket: bucketName,
    Key: name,
    Body: file,
    Metadata: {
      connectionId: connectionId || '',
      userInfo: JSON.stringify(userInfo),
    },
  });
  return s3Client.send(command);
}

export async function multipartUpload(
  s3Client: S3Client,
  { file, name, bucketName, connectionId, userInfo }: UploadS3ObjectInput,
) {
  const createMultipartUploadCommand = new CreateMultipartUploadCommand({
    Bucket: bucketName,
    Key: name,
    Metadata: {
      connectionId: connectionId || '',
      userInfo: JSON.stringify(userInfo),
    },
  });
  const { UploadId } = await s3Client.send(createMultipartUploadCommand);

  const fileSize = file.size;
  const partSize = 5 * 1024 * 1024; // 5MB
  const numParts = Math.ceil(fileSize / partSize);

  const limit = pLimit(5);

  const uploadPromises = Array.from({ length: numParts }).map((_, idx) => limit(() => uploadPart(idx + 1)));
  const uploadedParts = await Promise.all(uploadPromises);
  const completedParts = uploadedParts.map((part, index) => ({
    ETag: part.ETag,
    PartNumber: index + 1,
  }));

  const completeMultipartUploadCommand = new CompleteMultipartUploadCommand({
    Bucket: bucketName,
    Key: name,
    UploadId,
    MultipartUpload: { Parts: completedParts },
  });
  return s3Client.send(completeMultipartUploadCommand);

  async function uploadPart(partNumber: number) {
    const start = (partNumber - 1) * partSize;
    const end = Math.min(start + partSize, fileSize);
    const partParams: UploadPartCommandInput = {
      Bucket: bucketName,
      Key: name,
      UploadId,
      PartNumber: partNumber,
      Body: file.slice(start, end),
      ContentLength: end - start,
    };
    const uploadPartCommand = new UploadPartCommand(partParams);
    return s3Client.send(uploadPartCommand);
  }
}

export function getUploadStrategy(file: File) {
  return hasMoreThan100Mb(file) ? multipartUpload : singleUpload;
}

export async function listS3Objects(s3Client: S3Client, input: ListObjectsV2CommandInput) {
  let continuationToken: string | undefined = input.ContinuationToken;
  const allObjects = [];

  do {
    // eslint-disable-next-line no-await-in-loop
    const result = await s3Client.send(
      new ListObjectsV2Command({
        ...input,
        ContinuationToken: continuationToken,
      }),
    );

    allObjects.push(...(result.Contents || []));
    continuationToken = result.ContinuationToken;
  } while (continuationToken);

  return allObjects;
}

export async function listS3ImportObjects(
  s3Client: S3Client,
  { bucketName, type, site, status = 'archived' }: ListS3ObjectsInput,
) {
  const allObjects = await listS3Objects(s3Client, { Bucket: bucketName, Prefix: `${site}/${type}/${status}` });
  return allObjects.sort((first, second) => {
    const firstDate = first.Key?.split('/').splice(-1)[0].split('_')[2];
    const secondDate = second.Key?.split('/').splice(-1)[0].split('_')[2];
    return String(firstDate) > String(secondDate) ? -1 : 1;
  });
}

export async function s3ObjectUrl(s3Client: S3Client, bucketName: string, fileName: string) {
  const command = new GetObjectCommand({
    Bucket: bucketName,
    Key: fileName,
  });
  return getSignedUrl(s3Client, command, { expiresIn: 12 * 3600 });
}

export function onDownloadS3Object(s3Client: S3Client, bucketName: string, fileName: string) {
  return async () => window.open(await s3ObjectUrl(s3Client, bucketName, fileName));
}

export function formatDate(date: Date): string {
  return format(date, 'MMMM do, yyyy, h:mm a');
}

export function getObjectInfo(object: _Object): S3Object {
  const name = object.Key?.split('/').splice(-1)[0] as string;
  const objectInfo = decodeFileInformation(name);

  return {
    ...objectInfo,
    name,
    formattedDate: objectInfo.date ? formatDate(parseFileNameDate(objectInfo.date)) : '',
  };
}
