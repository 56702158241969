import type { InvokeCommandOutput } from '@aws-sdk/client-lambda';
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda';
import { useCallback, useState } from 'react';
import { useSettings, useSocket } from '../context';
import type { ExportInput } from '../types';
import { useBusinessToolsSelector } from '../store/businessToolsStore';

let client: LambdaClient | null = null;

export function useLambdaClient() {
  const { accessKey, accessSecret, awsRegion } = useSettings();
  if (!client) {
    client = new LambdaClient({
      region: awsRegion,
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey: accessSecret,
      },
    });
  }
  return client;
}

export function useInvokeExportLambda({ exportType }: ExportInput) {
  const lambdaClient = useLambdaClient();
  const { connectionId } = useSocket();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<InvokeCommandOutput | null>(null);
  const { exportLambdaName, userInfo } = useSettings();
  const config = useBusinessToolsSelector((state) => state.config);

  const invokeExportLambda = useCallback(
    async (payload: Record<string, unknown> = {}) => {
      setLoading(true);
      const result = await lambdaClient.send(
        new InvokeCommand({
          FunctionName: exportLambdaName,
          Payload: JSON.stringify({
            ...payload,
            exportType,
            connectionId,
            userKey: userInfo.email.split('@')[0],
            site: config.currentSite,
          }),
          InvocationType: 'Event',
        }),
      );
      setLoading(false);
      setData(result);
    },
    [connectionId, exportLambdaName, exportType, lambdaClient, userInfo.email, config],
  );

  return { invokeExportLambda, data, loading };
}
