import { gql, useQuery } from '@apollo/client';
import { GRAPHQL_TARGETS } from '@commercetools-frontend/constants';
import Text from '@commercetools-uikit/text';
import LoadingSpinner from '@commercetools-uikit/loading-spinner';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import styled from 'styled-components';
import type { UserInfo } from '../types';

export interface Settings {
  userInfo: UserInfo;
  accessKey: string;
  accessSecret: string;
  importReportsBucketName: string;
  envName: string;
  importBucketName: string;
  exportReportsBucketName: string;
  exportLambdaName: string;
  awsRegion: string;
  socketUrl: string;
}

const SettingsContext = createContext<Settings>(null!);
export const useSettings = () => useContext(SettingsContext);

const S = {
  Wrapper: styled.main`
    margin: auto;
  `,
};

const UserInfoQuery = gql`
  query {
    me {
      firstName
      lastName
      email
      id
    }
  }
`;

const FetchSettings = gql`
  query {
    customObject(container: "custom-app-env", key: "env") {
      value
    }
  }
`;

export function SettingsProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(FetchSettings, {
    context: {
      target: GRAPHQL_TARGETS.COMMERCETOOLS_PLATFORM,
    },
  });

  const { data: userData } = useQuery(UserInfoQuery, {
    context: {
      target: GRAPHQL_TARGETS.COMMERCETOOLS_PLATFORM,
    },
  });

  const settings = useMemo(() => data?.customObject?.value || {}, [data?.customObject?.value]);
  const userInfo = useMemo(() => userData.me, [userData.me]);

  const value: Settings = useMemo(() => ({ ...settings, userInfo }), [settings, userInfo]);

  if (loading) {
    return (
      <S.Wrapper>
        <LoadingSpinner scale="l" />
      </S.Wrapper>
    );
  }
  if (error) {
    return (
      <Text.Headline as="h1">
        An error occurred while retrieving settings, please contact the maintainer of the application.
      </Text.Headline>
    );
  }

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}
