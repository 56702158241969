import type { ReactNode, ReactElement } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useShowNotification } from '@commercetools-frontend/actions-global';
import { DOMAINS, NOTIFICATION_KINDS_SIDE, NOTIFICATION_KINDS_PAGE } from '@commercetools-frontend/constants';

interface GlobalNotificationContext {
  error: (message: string, onDismiss?: () => void) => void;
  success: (message: string, onDismiss?: () => void) => void;
}

const initial: GlobalNotificationContext = {
  error: () => {},
  success: () => {},
};

const GlobalNotificationContext = createContext(initial);

export function GlobalNotificationProvider({ children }: { children: ReactNode }): ReactElement {
  const showNotification = useShowNotification();

  const error = useCallback(
    (message: string, onDismiss?: () => void) => {
      showNotification(
        {
          kind: NOTIFICATION_KINDS_PAGE.error,
          domain: DOMAINS.PAGE,
          text: message,
        },
        { onDismiss },
      );
    },
    [showNotification],
  );

  const success = useCallback(
    (message: string, onDismiss?: () => void) => {
      showNotification(
        {
          kind: NOTIFICATION_KINDS_SIDE.success,
          domain: DOMAINS.SIDE,
          text: message,
        },
        { onDismiss },
      );
    },
    [showNotification],
  );

  return (
    <GlobalNotificationContext.Provider value={useMemo(() => ({ error, success }), [error, success])}>
      {children}
    </GlobalNotificationContext.Provider>
  );
}

export function useGlobalNotification(): GlobalNotificationContext {
  return useContext(GlobalNotificationContext);
}
