import type { ExportType, ImportType } from '@ct/import-export';
import type { Store } from '@ct/types';

export enum SiteName {
  BEAVERBROOKS = 'Beaverbrooks',
  LOUPE = 'Loupe',
}

export interface ListS3ObjectsInput {
  bucketName: string;
  type: ImportType | ExportType;
  status: 'archived' | 'errors';
  site: Store;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
}

export interface UploadS3ObjectInput {
  file: File;
  name: string;
  bucketName: string;
  connectionId?: string;
  userInfo?: UserInfo;
}

export enum ActionStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface S3Object {
  formattedDate: string;
  date: string;
  name: string;
  action: 'import' | 'export';
  status: ActionStatus;
  type: ImportType | ExportType;
  userKey: string;
  hasDeleteOperation: boolean;
  onDownload: () => void;
}

export interface ExportInput {
  exportType: ExportType;
}

export enum SocketMessageType {
  CONNECTION_ID = 'CONNECTION_ID',
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT',
}

export interface SocketConnectionIdMessage {
  type: SocketMessageType.CONNECTION_ID;
  connectionId: string;
}

export interface ExportMessage {
  type: SocketMessageType.EXPORT;
  exportFileName: string;
  exportType: ExportType;
  error?: unknown;
}

export interface ImportMessage {
  type: SocketMessageType.IMPORT;
  importFileName: string;
  importType: ImportType;
  error?: unknown;
}

export type SocketMessage = SocketConnectionIdMessage | ExportMessage | ImportMessage;
