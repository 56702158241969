/* eslint-disable no-restricted-syntax */
import { SSMClient, paginateGetParametersByPath } from '@aws-sdk/client-ssm';
import { useEffect, useMemo, useState } from 'react';
import { format, parse } from 'date-fns';
import { useBusinessToolsSelector } from '../store/businessToolsStore';
import { useSettings } from '../context';

export function useGetParamsByPrefix(prefix: string): Record<string, string> {
  const [params, setParams] = useState<Record<string, string>>({});
  const { awsRegion, accessKey, accessSecret } = useSettings();
  const { importInProgress } = useBusinessToolsSelector((state) => state.importExport);

  const client = useMemo(
    () =>
      new SSMClient({
        region: awsRegion,
        credentials: {
          accessKeyId: accessKey,
          secretAccessKey: accessSecret,
        },
      }),
    [accessKey, accessSecret, awsRegion],
  );

  useEffect(() => {
    (async () => {
      const result: Record<string, string> = {};
      const paramsIterator = paginateGetParametersByPath(
        { client, pageSize: 10 },
        {
          Path: prefix,
          Recursive: true,
          WithDecryption: true,
        },
      );

      for await (const { Parameters = [] } of paramsIterator) {
        for (const { Name, Value } of Parameters) {
          const name = Name?.split('/').pop();
          if (name && Value) {
            const formatString = Value.length === 14 ? 'yyyyMMddHHmmss' : 'yyyyMMddHHmm';
            const parsedDate = parse(Value, formatString, new Date());
            result[name] = format(parsedDate, 'MMMM do, yyyy, h:mm a');
          }
        }
      }
      setParams(result);
    })();
  }, [client, prefix, importInProgress]);

  return params;
}
