/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ExportType, ImportType } from '@ct/import-export';
import type { ExportMessagePayload, ImportMessagePayload } from './types';

export interface ImportExportState {
  importInProgress: Record<ImportType, boolean> | null;
  exportInProgress: Record<ExportType, boolean> | null;
  importMessages: ImportMessagePayload[];
  exportMessages: ExportMessagePayload[];
  lastImports: Partial<Record<ImportType, string>>;
}

const initialState: ImportExportState = {
  importInProgress: null,
  exportInProgress: null,
  importMessages: [],
  exportMessages: [],
  lastImports: {},
};

function updateProgress<K extends string | number | symbol>(
  obj: Record<K, boolean> | null,
  type: K,
  value: boolean,
): Record<K, boolean> {
  const newObj = { ...(obj || {}) } as Record<K, boolean>;
  if (value) {
    newObj[type] = true;
  } else {
    delete newObj[type];
  }
  return newObj;
}

export const importExportSlice = createSlice({
  name: 'importExport',
  initialState,
  reducers: {
    addImportMessage: (state, action: PayloadAction<ImportMessagePayload>) => {
      state.importMessages.push(action.payload);
    },

    addExportMessage: (state, action: PayloadAction<ExportMessagePayload>) => {
      state.exportMessages.push(action.payload);
    },

    setImportProgress: (state, action: PayloadAction<{ type: ImportType; value: boolean }>) => {
      state.importInProgress = updateProgress(state.importInProgress, action.payload.type, action.payload.value);
    },

    setExportProgress: (state, action: PayloadAction<{ type: ExportType; value: boolean }>) => {
      state.exportInProgress = updateProgress(state.exportInProgress, action.payload.type, action.payload.value);
    },

    setLastImports: (state, action: PayloadAction<Partial<Record<ImportType, string>>>) => {
      state.lastImports = action.payload;
    },

    reset: (state) => ({ ...initialState, lastImports: state.lastImports }),
  },
});

export const { addImportMessage, addExportMessage, setImportProgress, setExportProgress, setLastImports, reset } =
  importExportSlice.actions;

export default importExportSlice.reducer;
