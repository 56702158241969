import type { CompleteMultipartUploadCommandOutput, PutObjectCommandOutput } from '@aws-sdk/client-s3';
import { S3Client } from '@aws-sdk/client-s3';
import { useCallback, useEffect, useState } from 'react';
import type { ExportType, ImportType } from '@ct/import-export';
import { useBusinessToolsSelector } from '../store/businessToolsStore';
import { getObjectInfo, getUploadStrategy, listS3ImportObjects, onDownloadS3Object } from '../utils';
import { useSettings, useSocket } from '../context';
import { ActionStatus, type S3Object, type UploadS3ObjectInput } from '../types';

let client: S3Client | null = null;

export function useS3Client() {
  const { accessKey, accessSecret, awsRegion } = useSettings();
  if (!client) {
    client = new S3Client({
      region: awsRegion,
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey: accessSecret,
      },
    });
  }
  return client;
}

export function useUploadS3Object() {
  const { connectionId } = useSocket();
  const { userInfo } = useSettings();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PutObjectCommandOutput | CompleteMultipartUploadCommandOutput | null>(null);
  const s3Client = useS3Client();

  const uploadS3Object = useCallback(
    async (input: UploadS3ObjectInput) => {
      setLoading(true);
      const upload = getUploadStrategy(input.file);
      const result = await upload(s3Client, { connectionId, userInfo, ...input });
      setLoading(false);
      setData(result);
    },
    [connectionId, s3Client, userInfo],
  );

  return { loading, uploadS3Object, data };
}

export function useListS3Objects(type: ImportType | ExportType, bucketName: string, action: 'import' | 'export') {
  const [objects, setObjects] = useState<S3Object[] | null>(null);
  const [loading, setLoading] = useState(false);
  const { currentSite: site } = useBusinessToolsSelector((state) => state.config);
  const s3Client = useS3Client();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const dataArchived = await listS3ImportObjects(s3Client, { bucketName, site, type, status: 'archived' });
      const dataErrors = await listS3ImportObjects(s3Client, { bucketName, type, site, status: 'errors' });

      setObjects([
        ...dataArchived.map((item) => ({
          ...getObjectInfo(item),
          status: ActionStatus.SUCCESS,
          type,
          action,
          onDownload: onDownloadS3Object(s3Client, bucketName, item.Key as string),
        })),
        ...dataErrors.map((item) => ({
          ...getObjectInfo(item),
          status: ActionStatus.ERROR,
          type,
          action,
          onDownload: onDownloadS3Object(s3Client, bucketName, item.Key as string),
        })),
      ]);
      setLoading(false);
    })();
  }, [type, s3Client, bucketName, action, site]);

  return { objects, loading };
}

export function useListS3ImportObjects(importType: ImportType) {
  const { importReportsBucketName } = useSettings();
  return useListS3Objects(importType, importReportsBucketName, 'import');
}

export function useListS3ExportObjects(exportType: ExportType) {
  const { exportReportsBucketName } = useSettings();
  return useListS3Objects(exportType, exportReportsBucketName, 'export');
}
