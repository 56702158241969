import type { ImportType } from '@ct/import-export/src/types';
import { format, parse } from 'date-fns';
import type { Store } from '@ct/types';

interface ImportFileNameInput {
  site: Store;
  userKey: string;
  resource: ImportType;
  staged?: boolean;
}

interface DecodedFileInformation extends ImportFileNameInput {
  date: string;
  hasDeleteOperation: boolean;
}

const FILE_NAME_SEPARATOR = '_';

export function parseFileNameDate(date: string) {
  return parse(date, 'yyyyMMddHHmmss', new Date());
}

export function getImportFileName({ site, userKey, resource, staged }: ImportFileNameInput) {
  return `${[site, resource, format(new Date(), 'yyyyMMddHHmmss'), staged ? 'staged' : '', userKey].join(
    FILE_NAME_SEPARATOR,
  )}.csv`;
}

export function decodeFileInformation(fileName: string): DecodedFileInformation {
  const separatedFileParts = fileName.split('.')[0].split(FILE_NAME_SEPARATOR);
  const [site, resource, date, staged, userKey, hasDeleteOperation] = separatedFileParts;

  return {
    site,
    resource,
    userKey,
    date,
    staged: staged === 'staged',
    hasDeleteOperation: hasDeleteOperation === 'delete',
  } as DecodedFileInformation;
}

export function getFileSizeInMb(file: File) {
  return file.size / 1024 / 1024;
}

export function hasMoreThan100Mb(file: File) {
  return getFileSizeInMb(file) > 100;
}

export function triggerFileDownload(url: string) {
  if (!document) return;

  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
